import React from 'react';
import BoardList from './BoardList';

const BoardMain = (props) => {
    return (
        <>
            <div className="BoardList">
                <BoardList />
            </div>
        </>
    );
};

export default BoardMain;
