const boardList = [
    {
        no: 1,
        tag: '',
        title: "유학 간 자녀 부양가족 포함했다가 '청약 취소' 날벼락",
        link: 'http://realty.chosun.com/site/data/html_dir/2020/02/06/2020020601839.html',
        createDate: '2021-08-04',
        writer: '관리자',
        Link: `[땅집고] 아파트 청약을 할 때 유학 간 자녀를 부양가족으로 포함했다가 청약 당첨이 취소되고 부적격 처리되는 사례가 잇따르고 있다. 국토교통부가 ‘90일 이상 해외에 체류하는 등 청약 신청자와 함께 거주하고 있지 않은 피부양자는 부양가족으로 인정할 수 없다’는 유권해석을 내렸기 때문이다.
        
        6일 분양업계에 따르면 최근 일반분양을 진행한 서울 주요 단지에서 유학 간 자녀를 부양가족으로 포함해 청약가점을 계산했다가 뒤늦게 당첨이 취소된 사례들이 많았다. 지난달 분양을 마친 ‘개포프레지던스자이’와 ‘위례호반써밋’, ‘래미안라클래시’, ‘힐스테이트창경궁’ 등 최근 4개월간 서울에서 분양한 거의 모든 단지에서 비슷한 유형의 탈락자가 나왔다. 특정 단지에선 같은 이유로 당첨이 취소된 사례가 10건이 넘었다.
        
        현재 투기과열지구와 수도권 주요 지역, 대규모 택지개발지구 등에서는 입주자모집공고일 기준으로 최소 1년을 거주해야 주택의 해당 지역 우선공급 대상 자격을 얻는다. 작년 12월 정부가 ‘주택공급에 관한 규칙’ 개정안을 입법예고 하면서 이 1년은 2년으로 늘어날 예정이다.
        
        이러한 방침에 따라 해외 거주자는 우선공급을 받을 수 없다. 해외 거주자란, 서울을 예로들면 ‘최근 1년 이내에 출국한 후 연속해 90일을 초과 체류하거나 전체 해외 체류 기간이 183일을 넘긴 경우’로 규정된다. 기존에는 초과 체류 기간이 연속 90일이 아닌, 30일 이상으로 규정됐지만 해외 출장이나 연수 등을 나갔다가 부적격자가 된 사례가 많아지자 지난해 11월 제도가 완화됐다.
        `,
    },
    {
        no: 2,
        tag: '',
        title: '당첨 포기하면 가족이 10년간 청약금지',
        link: 'https://www.hankyung.com/realestate/article/202107271857i',
        createDate: '2021-08-04',
        writer: '관리자',
        Link: `주택에 당첨됐으나 개인 사정으로 계약을 포기한 경우, 재당첨 제한 등 청약제한사항이 적용될까. 무순위 청약으로 주택을 공급하는 경우 사업시행자는 입주자모집승인권자의 승인을 받아야 할까.

        지나치게 복잡한 주택 청약제도에 대한 이해를 돕고 의도치 않게 부적격 청약으로 피해를 보는 사례를 막기 위한 청약 참고서가 나왔다. 국토교통부는 주택청약 질의회신집(FAQ)을 27일 발간해 배포한다고 밝혔다. 질의 회신집은 청약 수요자들이 꼭 알아야 할 청약 자격이나 일반 공급 및 특별공급 요건, 사전 청약 등 주요 내용부터 사업 주체가 놓치기 쉬운 주택공급 절차 등을 담고 있다.
        
        주택에 당첨됐지만 개인 사정으로 계약을 포기한 경우 당첨자로 간주된다. 계약체결을 포기하거나 해지했더라도 실제 공급계약 체결 여부와 무관하게 당첨자로 관리되며, 청약통장을 사용해 당첨된 경우에는 청약통장 재사용도 제한된다.
        투기과열지구 및 청약과열지역에서 공급된 단지의 경우 주택공급에 관한 규칙에 따라 10년간 재당첨 제한을 적용받게 된다. 기존 주택 당첨으로 인해 재당첨 제한 기간 내에 있거나, 그 세대에 속한 자도 청약이 불가능하다.

        1주택자가 주택을 매도해 무주택자가 되는 경우에는 건물 등기사항증명서 상 등기접수일이 기준이 된다. 건축물대장등본 상 처리일도 기준으로 삼을 수 있다. 해당 서류가 존재하지 않을 경우 재산세 과세대장 상 납부대상자 변경일을 기준으로 무주택 기간을 산정한다.
        `,
    },
    {
        no: 3,
        tag: '',
        title: '청약열기 뜨겁던 별내자이, 부적격자 쏟아져',
        link: 'https://www.mk.co.kr/news/realestate/view/2020/11/1209107/',
        createDate: '2021-08-04',
        writer: '관리자',
        Link: `분양가상한제를 적용받아 청약 경쟁률이 203대1을 넘기는 등 뜨거운 관심을 받았던 남양주 '별내 자이 더 스타'에서 당첨자 10명 중 1명에 가까운 부적격자가 속출한 것으로 나타났다.

        24일 GS건설에 따르면 남양주 별내 자이 더 스타 일반 공급 421가구 중 39가구(9.2%)가 부적격 당첨자로 밝혀졌다. 전용면적별로는 84A 9가구, 84B 15가구, 84C 6가구, 99A 3가구, 99B 6가구다.
        
        특별공급에서는 319가구 중 32가구가 부적격 당첨자로 부적격 당첨 비율이 10%를 넘겼다. 생애 최초 특별공급에는 8718명이 몰려 평균 경쟁률이 113.2대1에 달하기도 했다. 전용면적별로는 84A 10가구, 84B 13가구, 84C 5가구, 99A 2가구, 99B 2가구다.
        
        `,
    },
    {
        no: 4,
        tag: '',
        title: '친모 아니라서 아파트 청약당첨 취소',
        link: 'https://www.mk.co.kr/news/realestate/view/2021/03/216959/',
        createDate: '2021-08-04',
        writer: '관리자',
        Link: `“38년동안 어머니로 모셨는데 계모라서 당첨된 아파트 청약이 취소됐어요."

        올해 1월 경기도 의정부에서 공급된 새 아파트 청약에 당첨됐다가 계모를 모신다는 이유로 청약이 취소됐다는 주장이 제기돼 논란이다.
        
        증여세 공제·자동차보험 가족한정 특약 등에는 인정되는 계부, 계모 '가족관계'가 아파트 청약제도에서는 인정되지 않아 '시대착오적'이란 비판이 나온다.
        
        7일 청와대 국민청원 게시판에 따르면 지난 1월 의정부 '고산 수자인디에스티지' 아파트 분양에서 노부모부양 특별공급에 당첨됐다가 '부적격'으로 당첨이 취소된 청원인의 사연이 게재됐다.
        
        청원인은 난생 처음 아파트 청약에 당첨 돼 기쁜 마음으로 서류를 준비하고 관련 절차를 진행하던 중 "계모는 민법상의 직계존속이 아니다'라며 건설사로부터 청약 부적격자로 당첨 취소 통보를 받았다.
        
        청원인은 "아버지께서 제가 5살 때 어머니를 떠나 보내시고 지금의 어머니(계모)와 1983년에 재혼후 현재까지 38년을 함께 살고 있다. 아버지는 지난 2015년 작고하셨다"고 언급했다.`,
    },
    {
        no: 5,
        tag: '',
        title: "수원에도 청약 부적격자 우수수…영통자이도 '줍줍'",
        link: 'https://www.mk.co.kr/news/realestate/view/2020/05/556296/',
        createDate: '2021-08-04',
        writer: '관리자',
        Link: `"부모님이 가구원으로 돼 있어 청약 넣으면 안되는데 신청해 당첨됐어요."

        "유주택자 아내랑 분리가구여서 상관없을 줄 알았는데 부적격 됐네요."
        
        청약 규정을 제대로 확인하지 않고 무턱대고 청약을 넣었다가 당첨이 취소되는 '부적격 당첨'이 쏟아지고 있다.
        
        지난 4월 일반 청약을 진행한 수원 영통자이에서도 부적격 당첨자가 속출해 3가구가 '줍줍(무순위 청약)' 대상으로 나왔다. 공공분양 마곡9단지에서도 '알고 보니 부적격 당첨'이 속출했다. 지난 2월 인기리에 청약을 마감한 매교역 푸르지오SK뷰에서는 전체 분양 가구의 13%가량이 부적격자였고, 공공분양 과천제이드자이에서도 22%가량이 부적격 당첨으로 확인된 바 있다.
        
        전문가들은 청약 경쟁률이 치열한 상황에서 '묻지마 청약'은 소중한 당첨 기회를 날리고 청약 제한까지 받으므로 대기자들은 꼼꼼하게 알아보고 청약을 넣어야 한다고 당부했다.
        
        31일 부동산업계에 따르면, GS건설은 수원 영통자이에서 부적격 당첨자가 발생함에 따라 전용 75㎡ 3가구(11층 이상)에 대해 오는 3일 무순위 청약을 받을 예정이다. 공급가는 5억5000만원 선이다. 무순위 청약으로 나온 전용 75㎡ 가구는 1순위 청약 당시 61가구 모집에 3200명 지원해 경쟁률이 52대1을 기록한 인기 타입이다. 예비 당첨자 3배수(300%)까지 뽑았으나 예비에서도 부적격자가 발생해 이번에 무순위 청약까지 오게 됐다.
        
        GS건설 관계자는 "1순위 청약 당첨자에서 부적격자가 발생했고 남은 물량은 예비 당첨자들에게 기회가 돌아갔지만 동·호수를 보고 일부 예비 당첨자들이 포기하거나 청약 부적격자가 발생해 무순위 청약이 나오게 됐다"고 했다.`,
    },
];

const getPostByNo = (no) => {
    const array = boardList.filter((x) => x.no == no);
    if (array.length == 1) {
        return array[0];
    }
    return null;
};

export { boardList, getPostByNo };
